<script setup lang="ts">
  definePageMeta({
    layout: "clean",
  });

  import { ImageButton } from '~/types';
  const teamStore = useTeamStore()
  const teamIcon = computed(() => `flag_${teamStore.teamName}`)

</script>

<template>
  <div class="warning_content">
    <OverlayImage :position="'left'" :modal="true" :url="$imageAssets[`bannerLocation1_illustration_${teamStore.program}`]"/>
    <OverlayIcon v-if="ImageButton.guard(teamIcon)" :url="$imageAssets[`${teamIcon}`]" alt="teamIcon" position="left" />
    <div class="warning_text-container">
      <TextHeading>{{ $t('team_regroup_heading') }}</TextHeading>
      <TextBody>{{ $t('team_regroup_body') }}</TextBody>
    </div>
  </div>
</template>

<style scoped>
@import "assets/styles/pages.css";

.warning_content {
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.warning_text-container {
  position: absolute;
  width: 500px;
  right: 150px;
  top: 169px;
}

</style>