<script setup lang="ts">


  import type {Team} from "~/types";

  const { getImage } = useAssets()
  const instructorStore = useInstructorStore()
  const { $instructorSocket } = useNuxtApp()

  if (!instructorStore.teams || instructorStore.teams.length === 0) {
    $instructorSocket.sessionInfo()
  }

  const showRefreshButton = ref(false)

  const refreshStatus = () => {
    $instructorSocket.sessionInfo()
  }

  const disconnectTeamTablet = (teamId: number) => {
    $instructorSocket.disconnectTeamTablet(teamId)
    showRefreshButton.value = true
  }

  // const handleSubmit = () => {
  //   console.log('form', form.code);
  //   $instructorSocket.socketConnect(form.code, nextStep)
  //
  //   console.log ('handledSubmit')
  // }

  const emit = defineEmits(['close'])
  const onReady = async () => {
    console.log ('onReady', instructorStore.session.active, instructorStore.activeSlotIdx)
    // navigateTo('/instructor', {replace: true})
    emit('close')
  }
</script>

<template>
  <div class="page_content">
    <div class="page_text-container center">
      <TextHeading style="text-align: center">{{ $t('teams_menu_heading') }}</TextHeading>
      <TextBody style="text-align: center">{{ $t('teams_menu_body') }} <span class="code">{{ instructorStore.secret.toUpperCase() }}</span></TextBody>
    </div>
    <div class="page_choose-image-container">
      <div v-for="( team, index ) in instructorStore.teams" :key="`team${index}`" style="position: relative" class="team-icon-container">
        <div style="width: 240px; margin: 0 auto;">
          <CircledImage :url="getImage(`flag_${team.name}.png`)" :alt="`flag_${team.name}`" class="team-icon" :class="{'active': !team.linked}" :shadow="false" />
          <TextBody v-if="!team.linked" class="linked">{{ $t('teams_unlinked') }}</TextBody>
        </div>
        <ButtonText class="disconnect" v-if="team.linked" :label="$t('teams_unlink')" icon="cancel" @click="disconnectTeamTablet(team.id)"></ButtonText>
      </div>
    </div>
    <div class="page_action-container" v-if="instructorStore.countLinkedTeams === 4">
      <ButtonText size="small" :label="$t('teams_btn_ready')" @click="onReady" />
    </div>
    <div class="page_back-container" v-if="showRefreshButton">
      <ButtonText size="small" :label="$t('teams_btn_refresh')" @click="refreshStatus" />
    </div>
  </div>
</template>



<style scoped>
@import "assets/styles/pages.css";
.code {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}
.disconnect {
  position: absolute;
  bottom: 0;
}

.team-icon-container {
  align-self: start;
}

.team-icon.active {
  opacity: 0.5;
}

.linked {
  width: 130px;
  text-align: center;
  position: absolute;
  top: 90px;
  left: 80px;
}
</style>