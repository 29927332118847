<script setup lang="ts">
const { showModal } = useModal()
</script>

<template>

  <div class="page_content">
    <OverlayImage :position="'left'" :url="$imageAssets['instructeur_illustration']"/>
    <div class="menu_action-container">
      <ButtonText label="Verzamelen" @click="showModal('regroup')" size="large" :margin-bottom="true"/>
      <ButtonText label="Tablets" @click="showModal('teams')" :margin-bottom="true"/>
      <ButtonText label="Afronden" @click="showModal('finish')" :margin-bottom="true"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "assets/styles/pages.css";

.menu_action-container {
  position: absolute;
  right: 250px;
  top: 155px;
  text-align: right;
}
</style>