<script setup lang="ts">
import {storeToRefs} from "pinia";

const { $instructorSocket } = useNuxtApp()
const instructorStore = useInstructorStore()
const courseStore = useCourseStore()
const { t } = useI18n()


// const { $sentryCaptureException } = useNuxtApp();
//
// onMounted(() => {
//   $sentryCaptureException("Test exception");
// });

const pageContent =  {
  title: t('timeIsUp_heading'),
  body: t('timeIsUp_body'),
  button: t('timeIsUp_button')
}

const showContent = ref(true)
const emit = defineEmits(['close'])

const onButtonClick = () => {
  emit('close')
}
</script>

<template>
  <div v-if="showContent" class="page_content">
    <OverlayImage :position="'left'" :url="$imageAssets['sneller_illustration']"/>
    <div class="page_text-container">
      <TextHeading>{{ pageContent.title }}</TextHeading>
      <TextSubHeading>{{ pageContent.body }}</TextSubHeading>
    </div>
<!--    <div class="page_action-container">-->
<!--      <ButtonText size="normal" :label="pageContent.button" @click="onButtonClick" />-->
<!--    </div>-->
  </div>
</template>

<style scoped>
@import "assets/styles/pages.css";

</style>