<script setup lang="ts">
import {storeToRefs} from "pinia";

const { $instructorSocket } = useNuxtApp()
const instructorStore = useInstructorStore()
const courseStore = useCourseStore()
const { t } = useI18n()


// const { $sentryCaptureException } = useNuxtApp();
//
// onMounted(() => {
//   $sentryCaptureException("Test exception");
// });



const { activityState } = storeToRefs(courseStore);
const pageContent =  {
  title: t('finish_heading'),
  body: t('finish_body'),
  button: t('finish_button')
}

const showContent = ref(true)
const emit = defineEmits(['close'])

const onButtonClick = () => {
  let destination = '/instructor'

  // console.log('onButtonClick', activityState.value)

  $instructorSocket.sessionDelete( ()=> {
    navigateTo(destination, {replace: true})
  });

  emit('close')

}
</script>

<template>
  <div v-if="showContent" class="page_content">
    <OverlayImage :position="'left'" :url="$imageAssets['quiz_illustration']"/>
    <div v-if="instructorStore.session.active && pageContent" class="page_text-container">
      <TextHeading>{{ pageContent.title }}</TextHeading>
      <TextSubHeading>{{ pageContent.body }}</TextSubHeading>
    </div>
    <div class="page_action-container">
      <ButtonText size="normal" :label="pageContent.button" @click="onButtonClick" />
    </div>
  </div>
</template>

<style scoped>
@import "assets/styles/pages.css";

</style>