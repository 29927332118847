<script setup lang="ts">
  definePageMeta({
    layout: "clean",
  });

  import { ImageButton } from '~/types';

  const teamStore = useTeamStore()
  const { $teamSocket } = useNuxtApp()

  // console.log("team manage players: session?: ", teamStore.session)

  const selectingAvatar = ref(false)

  const avatars = ref([
    {id: 1, name: 'dameshoed', disabled: false},
    {id: 2, name: 'feesthoedje', disabled: false},
    {id: 3, name: 'helm', disabled: false},
    {id: 4, name: 'hoed', disabled: false},
    {id: 5, name: 'klep', disabled: false},
    {id: 6, name: 'koptelefoon', disabled: false},
    {id: 7, name: 'muts', disabled: false},
    {id: 8, name: 'pet', disabled: false}
  ])

  const availableAvatars = computed(() => {
    avatars.value.forEach((avatar) => avatar.disabled = teamStore.members.some(member => member.avatar ===avatar.name))
    return avatars.value
  })

  const addMember = (event: MouseEvent) => {
    if (event.altKey) {
      autoFillMembers()
    } else {
      // console.log("add player")
      selectingAvatar.value = true
    }

  }

  const autoFillMembers = () => {
    console.log("add other players")

    const shuffledAndEnabledAvatars = availableAvatars.value.filter(avatar => !avatar.disabled).sort(() => 0.5 - Math.random())
    console.log('shuffledAndDisabledAvatars', shuffledAndEnabledAvatars)

    const selectTheseAvatars = shuffledAndEnabledAvatars.slice(0, 4 - (availableAvatars.value.length - shuffledAndEnabledAvatars.length));
    console.log('selectTheseAvatars', selectTheseAvatars, availableAvatars.value)

    selectTheseAvatars.forEach(avatar => {
      confirmAvatar(avatar.name)
    })

    onReady()
  }

  const confirmAvatar = (avatarName: string) => {

    console.log("confirm", avatarName)
    $teamSocket.teamAddMember(avatarName)
    selectingAvatar.value = false

  }

  const removeMember = (memberId: number) => {
    console.log("remove player")
    $teamSocket.teamDeleteMember(memberId)
  }

  const teamIcon = computed(() => `flag_${teamStore.teamName}`)

  const emit = defineEmits(['close'])
  const onReady = async () => {
    emit('close')
  }

  const route = useRoute();
  if (route.query.autoFill) {
    autoFillMembers()
  }
</script>

<template>
  <div class="page_content">
    <OverlayIcon v-if="ImageButton.guard(teamIcon)" :url="$imageAssets[`${teamIcon}`]" alt="teamIcon" position="left" />
    <div v-if="!selectingAvatar" class="page_text-container center">
      <TextBody>Wie zitten er in het team?</TextBody>
    </div>
    <div v-if="!selectingAvatar" class="page_members-container">
      <div v-for="(member, index) in teamStore.members" :key="`member${index}`">
        <Avatar v-if="member.id" width="257px" :avatar="`team_${teamStore.teamName}_${member.avatar}`" path="/assets/images/teams" @click="removeMember(member.id)" />
        <div class="member-label">Speler {{ index + 1 }}</div>
      </div>
      <div v-if="teamStore.members.length < 4">
        <Avatar avatar="" width="257px" @click="addMember" />
        <div class="member-label">Speler {{ teamStore.members.length+1 }}</div>
      </div>

    </div>
    <SelectPlayerAvatar v-if="selectingAvatar" :prefix="`team_${teamStore.teamName}_`" :members="teamStore.members.length + 1" :avatars="availableAvatars" @confirm="confirmAvatar" @cancel="selectingAvatar = false"></SelectPlayerAvatar>
    <div v-else class="page_action-container">
      <ButtonText v-if="teamStore.members.length >= 2" size="small" label="Klaar" @click="onReady" />
    </div>
  </div>
</template>

<style scoped>
@import "assets/styles/pages.css";

.page_members-container {
  display: flex;
  justify-content: center;

  position: relative;
  top: 230px;
  width: 100%;
  padding: 0 30px;
  flex-wrap: wrap;
  gap: 40px;
}

.member-label {
  color: var(--wit, #FFF);
  text-align: center;
  /* Heading 3 */
  font-family: Arial;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>