<script setup lang="ts">


import type {Team} from "~/types";

const { getImage } = useAssets()
const instructorStore = useInstructorStore()
const { sessionInfo, returnToMeetingPoint } = useInstructorSocket()

if (!instructorStore.teams || instructorStore.teams.length === 0) {
  sessionInfo()
}

const regroupTeams = ref<{ id: number, name: string, linked: boolean | undefined, disabled: boolean, checked: boolean }[]>([])
regroupTeams.value = instructorStore.teams.map((team) => {
  return {
    id: team.id,
    name: team.name,
    linked: team.linked,
    disabled: !team.linked,
    checked: team.returnToMeetingPoint || false
  }
})
// const handleSubmit = () => {
//   console.log('form', form.code);
//   $instructorSocket.socketConnect(form.code, nextStep)
//
//   console.log ('handledSubmit')
// }

const emit = defineEmits(['close'])
const onReady = async () => {
  console.log ('onReady', instructorStore.session.active, instructorStore.activeSlotIdx)
  // navigateTo('/instructor', {replace: true})
  emit('close')
}

const toggleRegroup = (toggledTeam: { id: number, name: string, linked: boolean | undefined, disabled: boolean, checked: boolean }) => {
  const teamToToggle = regroupTeams.value.find((team) => team.id === toggledTeam.id)
  if (teamToToggle) {
    teamToToggle.checked = !teamToToggle?.checked
    returnToMeetingPoint(teamToToggle.id, teamToToggle.checked)

  }

  console.log('toggleRegroup', teamToToggle)
}

</script>

<template>
  <div class="page_content">
    <div class="page_text-container center">
      <TextHeading style="text-align: center">{{ $t('regroup_heading') }}</TextHeading>
      <TextBody style="text-align: center">{{ $t('regroup_body') }}</TextBody>
    </div>
    <div class="page_choose-image-container">
      <ButtonImageCheck name="card-check"
                        v-for="( team, index ) in regroupTeams"
                        :key="`answer-card${index}-${team.checked}`"
                        :value="team"
                        :index="index"
                        :checked="team.checked"
                        prefix="flag_"
                        class="team-icon"
                        style="width: 240px; margin: 0 auto;"
                        @click="toggleRegroup(team)"
                       />
<!--          <ButtonImage class="team-icon" :disabled="!team.linked" :url="getImage(`flag_${team.name}.png`)" :circle="true" @click="toggleRegroup(team)"/>-->
<!--          <CircledImage :url="getImage(`flag_${team.name}.png`)" :alt="`flag_${team.name}`" class="team-icon" :class="{'active': !team.linked}" :shadow="false" />-->

<!--        <ButtonText class="disconnect" v-if="team.linked" :label="$t('teams_unlink')" icon="cancel" @click="disconnectTeamTablet(team.id)"></ButtonText>-->

    </div>
<!--    <div class="page_action-container" v-if="instructorStore.countLinkedTeams === 4">-->
<!--      <ButtonText size="small" :label="$t('teams_btn_ready')" @click="onReady" />-->
<!--    </div>-->
    <!--    <div class="page_back-container">-->
    <!--      <ButtonArrow direction="left"  @click="navigateTo('/instructor/bootstrap/selectProgram', {replace: true})" />-->
    <!--    </div>-->
  </div>
</template>



<style scoped>
@import "assets/styles/pages.css";
.code {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}
.disconnect {
  position: absolute;
  bottom: 0;
}

.team-icon-container {
  align-self: start;
}

.team-icon.active {
  opacity: 0.5;
}

.linked {
  width: 130px;
  text-align: center;
  position: absolute;
  top: 90px;
  left: 80px;
}
</style>