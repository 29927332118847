<script setup lang="ts">
  import type {ModalType} from "~/types";
  import {storeToRefs} from "pinia";
  import { useSound } from '@vueuse/sound'
  import returnToInstructor from '~/assets/sounds/returnToInstructor.mp3'
  const { play } = useSound(returnToInstructor)

  const { state, showModal } = useModal()

  const teamStore = useTeamStore()

  const { returnToMeetingPoint } = storeToRefs(teamStore);

  watch(returnToMeetingPoint, () => {
    console.log('activeSlide changed', returnToMeetingPoint.value)
    if (returnToMeetingPoint.value) {
      showModal('returnToMeetingPoint')
      play()
    } else if (state.type === 'returnToMeetingPoint') {
      state.show = false
    }
  },{
    immediate:true
  });

</script>

<template>
  <div v-show="state.show" class="modal-overlay">
    <div class="modal">
      <Background :background="'left'" />
      <ButtonImage v-if="!['returnToMeetingPoint', 'members'].includes(state.type)" class="close-button" :url="$imageAssets['btn_close']" :circle="false" :image-style="{width: '105px'}" @click="state.show = false"/>
      <ButtonImage v-if="!['menu', 'program', 'returnToMeetingPoint', 'members', 'timeIsUp'].includes(state.type)" class="previous-button" :url="$imageAssets['btn_previous']" :circle="false" :image-style="{width: '105px'}" @click="state.type = 'menu'"/>

      <ModalInstructorMenu v-if="state.type === 'menu'" @close="state.show = false" />
      <ModalFinishCourse v-if="state.type === 'finish'" @close="state.show = false" />
      <ModalTimeIsUp v-if="state.type === 'timeIsUp'" @close="state.show = false" />
      <ModalManageTeams v-if="state.type === 'teams'" @close="state.show = false" />
      <ModalRegroup v-if="state.type === 'regroup'" @close="state.show = false" />
      <ModalShowProgram v-if="state.type === 'program'" @close="state.show = false" />
      <ModalTeamMembers v-if="state.type === 'members'" @close="state.show = false" />
      <ModalTeamReturnToMeetingPoint v-if="state.type === 'returnToMeetingPoint'" @close="state.show = false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/pages.css";

.modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.40);
  z-index: 9999;
}

.modal {
  position: absolute;
  top: 35px;
  bottom: 35px;
  left: 35px;
  right: 35px;

  display: flex;

  .previous-button {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .page_text-container {
    width: 549px;
    right: 187px;
    top: 128px;
  }

  .page_action-container {
    right: 216px;
    bottom: 316px;
    text-align: right;
  }
}

.modal-content {
  position: absolute;
}

.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}
</style>