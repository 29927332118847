<script setup lang="ts">

const instructorStore = useInstructorStore()
const courseStore = useCourseStore()
const resetSessionToStart = (event: MouseEvent) => {
  instructorStore.startCourse(() => {
    navigateTo('/instructor', {replace: true})
  })
}

const nextSlot = (event: MouseEvent) => {
  instructorStore.nextSlot(() => {
    navigateTo('/instructor', {replace: true})
  })
}

const getActivityContent = (teamIndex: number, slotId: string) => {
  const slotIndex = courseStore.activitySlots.findIndex((as) => as.id === slotId)
  const activity = courseStore.schedule.teams[teamIndex].activities[slotIndex]
  return courseStore.content[activity]
}

const runtimeConfig = useRuntimeConfig()

</script>

<template>
  <div class="program_content">
    <div class="program-container">
      <CircledImage class="program-logo" :url="$getImage(`icon_${instructorStore.program}.png`)" :shadow="false" alt="program" :image-style="{width: '158px', height: '158px'}" />

      <div class="table-container">
        <!-- Header cells -->
        <!-- loopje over blokken, met duraties en starttijd? -->
        <div class=""></div>
        <template v-for="(slot, index) in courseStore.schedule.slots">
          <div v-if="index > 0" class="header-cell" :class="{'even': (index % 2)}">{{ slot.duration>0 ? 'Duur: ' + slot.duration + 'min':'' }}</div>
        </template>

        <!-- Data cells -->
        <!-- You can generate these dynamically using a loop in your server-side code or JavaScript -->

        <!-- loopje over teams -->
        <template v-for="(team, index) in instructorStore.teams">
          <div class="data-cell team-name" :class="{'evenRow': !(index % 2)}">
            TEAM <CircledImage :url="$imageAssets[`flag_${team.name}`]" :shadow="false" alt="program" :image-style="{width: '128px', height: '128px'}" />
          </div>
          <!-- loopje content blokken per team-->
          <template v-for="(slot, idx) in courseStore.schedule.slots">
            <div v-if="idx > 0 && slot.type === 'slot'" class="data-cell" :class="{'even': (idx % 2), 'evenRow': !(index % 2)}">
              {{ getActivityContent(index, slot.id).label }}
              <span class="location" v-if="getActivityContent(index, slot.id).location">
                <br />
                {{getActivityContent(index, slot.id).location}}</span>
            </div>
            <div v-else-if="idx > 0 && slot.type != 'slot'" class="data-cell" :class="{'even': (idx % 2), 'evenRow': !(index % 2)}">{{ slot.label }}</div>
          </template>
        </template>


        <!-- Add more rows and columns as needed -->
      </div>
    </div>
    <div v-if="runtimeConfig.public.dev" class="page_action-container">
      <ButtonText label="Next" @click="nextSlot" :margin-bottom="true"/>
      <ButtonText label="Reset" @click="resetSessionToStart" :margin-bottom="true"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "assets/styles/pages.css";

.program_content {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.program-container {
  position: relative;
  bottom: -90px;
  left: 0;

  font-family: var(--text-font);
  color: var(--text-color-white);
}

.program-logo {
  position: absolute;
  top: -75px;
  left: 70px;
}

.table-container {
  display: grid;
  grid-template-columns: 275px repeat(12, 1fr); /* Change the number of columns as needed */
  grid-template-rows: 60px repeat(4, 1fr);    /* Change the number of rows as needed */
  gap: 0;
  border: 0 solid #ccc;
  width: 3000px;
  height: 630px;
  padding: 30px
}

.header-cell,
.data-cell {
  padding: 10px;
  text-align: center;
  border: 0 solid #ccc;

  background: rgba(152, 0, 29, 0.30)
}

.header-cell {
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;

  display: flex;
  justify-content: center;
  align-items: center;

  &.even {
    background: rgba(152, 0, 29, 0.60)
  }
}

.data-cell {
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.10px;
  word-wrap: break-word;

  &.even {
    background: rgba(152, 0, 29, 0.60);

    &.evenRow {
      background: rgba(152, 0, 29, 0.90);
    }
  }
  &.evenRow {
    background: rgba(152, 0, 29, 0.60);
  }

  .location {
    color: white;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    line-height: 21.60px;
  }
}

.team-name {
  text-align: center;
  color: white;
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;

  padding: 1px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
</style>