<script setup lang="ts">
import { useSound } from '@vueuse/sound'
import click from '~/assets/sounds/click.mp3'
const { play } = useSound(click)

import type {CSSProperties} from "@vue/runtime-dom";

export interface RadioButtonProperties {
  name: string
  modelValue?: { id: number, name: string, disabled: boolean, linked?: boolean, checked?: boolean }[],
  value: { id: number, name: string, disabled: boolean, linked?: boolean, checked?: boolean },
  checked?: boolean
  prefix: string // shirt_
  style?: CSSProperties
  shadowOffset?: number
}
const props = defineProps<RadioButtonProperties>()
const emit = defineEmits(['click'])

// console.log('radio', `${props.prefix}${props.data.name}`)
const onClick = (event: MouseEvent) => {
  play()
  emit('click', event)
}
</script>

<template>
  <div class="checkbox-image" :style="style" @click="onClick($event)">
    <label>
      <input type="checkbox" :name="name"
             :value="value"
             class="checkbox"
             :disabled="value.disabled"
             :checked="checked || value.checked"
             >
      <span class="glow-container" :style="style"> <span class="glow-circle"></span> </span>
      <CircledImage :url="$getImage(`${prefix}${value.name}.png`)" :shadow="!value.disabled && !value.checked" :alt="`${prefix}${value.name}`" :image-style="style" :shadow-offset="shadowOffset" />
    </label>
  </div>
</template>

<style lang="scss" scoped>
  .checkbox-image {

    label {
      position: relative;

      .glow-container {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* IMAGE STYLES */
      input.checkbox + span.glow-container + div.circled-image {
        cursor: pointer;
      }

      input.checkbox:disabled + span.glow-container + div.circled-image {
        cursor: default;
        opacity: 0.5;
      }

      input.checkbox:checked + span.glow-container + div.circled-image {
        cursor: pointer;
      }
    }
  }

  /* HIDE RADIO */
  input.checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  @keyframes pulse {
    0% { box-shadow:0 0 10px yellow, inset 0 0 15px yellow; }
    50% { box-shadow:0 0 20px yellow, inset 0 0 30px yellow; }
    100% { box-shadow:0 0 10px yellow, inset 0 0 15px yellow; }
  }

  /* CHECKED STYLES */
  input.checkbox:checked + .glow-container {
    span.glow-circle {
      opacity: 1;
      background-color: transparent;
      width: 90%;
      height: 90%;
      border-radius: 100%;
      box-shadow: 0 0 10px yellow, inset 0 0 15px yellow;
      animation: pulse 3s linear 1s infinite;
    }
  }

  input.checkbox:disabled + .glow-container {
    span.glow-circle {
      opacity: 0;
    }
  }


</style>